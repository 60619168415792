import * as DotComConstants from './DotCom_Constants';
import * as Constants from '../Constants';
import logger from '@usb-ui-tools/logger';
import jslogger from '../JSONLoggerConstants.json';

const parseUrl = window.location.origin.split("/")[2];

export const handleSession = () => {
    // Code snippet to handle Transmit WebSDK backward compatability scenario on currentSession
    const currentSession = sessionStorage.getItem('currentSession');
              const parsedCurrentSession =(currentSession !==null && currentSession !== undefined && currentSession !== '') ? JSON.parse(currentSession) : '';
              const localUserStorage = localStorage.getItem('users');
              const parsedLocalUserStorage = (localUserStorage !== null && localUserStorage !== undefined && currentSession !== '') ? JSON.parse(localUserStorage) : '';

              var parlStorageUsers = '';

    if (parsedLocalUserStorage.length){
      for (var index = 0; index < parsedLocalUserStorage.length; ++index) {
        if(parsedLocalUserStorage[index].guid && parsedCurrentSession.user_guid && parsedLocalUserStorage[index].guid === parsedCurrentSession.user_guid) {
              parlStorageUsers = parsedLocalUserStorage[index];
              break;
        }
      }
    }

    const lUsersObj= {
      user : parlStorageUsers
    }
    const userNameObj = {
      user_name : sessionStorage.getItem('userId').toLowerCase()
    }

    if (typeof parsedCurrentSession == 'object' && !parsedCurrentSession.user){
      const currentSessionUsers = Object.assign({}, parsedCurrentSession,lUsersObj,userNameObj);
      sessionStorage.setItem('currentSession', JSON.stringify(currentSessionUsers))
    }
}

export const getDomain = {
  it: "it-api.usbank.com",
  uat: "alpha-api.usbank.com",
  emp: "api.usbank.com",
  prod: "api.usbank.com",
  preprod: "api.usbank.com",
  perf: "alpha-api.usbank.com",
  pentest: "alpha-api.usbank.com"
}

export const getEnvURL = (defaultKey = null) => {
  if (defaultKey === null) {
      return ((window && window.location) ? window.location.hostname.split(/[.,-]/)[0] : null);
  }
  return defaultKey;
}

export const getGraphqlUrl = () => {
  let graphqlUrl = '';

  if (window.location.host.startsWith("it")) {
    graphqlUrl = "it-api.usbank.com";
  } else if (window.location.host.startsWith("uat")) {
    graphqlUrl = "alpha-api.usbank.com";
  } else {
    graphqlUrl = "api.usbank.com";
  }

  return graphqlUrl;
}

export async function completeSignon(response) {
  var jwtBodyJson = parseJwt(response.token);
  var userToken = response.token;
  var tsParams = readSessionStorageItemsForTransmit(jwtBodyJson, response.token);
  const USER_ENABLED_FEATURES = {
    query: `query { userEnabledFeatures(finclsrvcftrcde:"",platform:"WEB") {
      features {
      feature
      platform
      enabled
      }}
    }`
  }
  
  const APPLICATION_ENABLED_FEATURES = {
    query: `query {applicationEnabledFeatures (finclsrvcftrcde:"", platform: "WEB"){
      features {
          feature
          platform
          enabled
      }}
    }`,
  };


  const getHost = () => {
      const host = getEnvURL();
      return host ? host.replace(/\d+|:/g, '') : '';
  }

  const env = getHost();

  const routingKeys = {
    it: getEnvURL('it3'),
    uat: getEnvURL('uat3'),
    localhost: getEnvURL('it3'),
    perf: getEnvURL(),
    pentest: getEnvURL(),
    emp: getEnvURL('emp'),
    preprod: getEnvURL('preprod'),
    prod: ''
  }

  const fetchFeatureFlags = async (query) => {
      let result;
      const response = await fetch(`https://${getGraphqlUrl()}/customer-management/graphql/v2`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${ jwtBodyJson && jwtBodyJson.accesstoken}`,
              routingKey: routingKeys[env] || routingKeys.prod
          },
          body: JSON.stringify(query)
      })
      if (response && response.ok) {
          const res = await response.json();
          result = res;
      } else {
          result = {};
      }
      return result;
  }
  
  if (jwtBodyJson.isGenerateDeviceToken) {
      redirectToFeatureRouter(userToken, tsParams);
  } else {
      const userFeatureFlags = await fetchFeatureFlags(USER_ENABLED_FEATURES);
      const applicationFeatureFlags = await fetchFeatureFlags(APPLICATION_ENABLED_FEATURES);
      const userFeature = userFeatureFlags.data && userFeatureFlags.data.userEnabledFeatures && userFeatureFlags.data.userEnabledFeatures.features;
      const applicationFeature = applicationFeatureFlags.data && applicationFeatureFlags.data.applicationEnabledFeatures && applicationFeatureFlags.data.applicationEnabledFeatures.features;

      redirectToFeatureRouter(userToken, tsParams, applicationFeature, userFeature);
      return;
  }
};

async function redirectToFeatureRouter(token, tsParams, applicationFeatureFlags, userFeatureFlags) {
  var JwToken = token;
  var decodedToken = parseJwt(token);
  var applyUrl = window.routerAppURL;
  var Accesstoken = decodedToken.accesstoken;
  var PilotFlags = decodedToken.pilotflags || decodedToken.pilotFlags;
  var isDIYPilot = decodedToken.isDIYPilot;
  var interactionId = decodedToken.InteractionId;
  var storage;

  sessionStorage.removeItem('InteractionID');

  tsParams.find(function(obj) {
      if (obj.Key === 'ts:appid') {
          window.appId = obj.Value;
      }
      return;
  });

  tsParams.find(function(obj) {
      if (obj.Key === 'ts:userid') {
          window.userId = obj.Value;
      }
      return;
  })

  tsParams.find(function(obj) {
      if (obj.Key === ('ts:deviceId' + ':' + window.userId)) {
          window.devId = obj.Value;
      }
      return;
  });

  tsParams.find(function(obj) {
      if (obj.Key === ('ts:sessionId' + ':' + window.userId)) {
      window.sesId = obj.Value;
      }
      return;
  })

  storage = window.sessionStorage;
  if (storage && storage.getItem("currentSession")) {
      window.curSession = storage.getItem("currentSession");
  }

  const localUserStorage = localStorage.getItem('users');

  var laform = document.createElement('form');
  laform.id = "laform";
  laform.method = "POST";
  laform.action = applyUrl;

  const isAppShellUserEnabled = (userFeatureFlags || [])?.find(flag => flag?.feature === "W_CDAS")?.enabled
  const isAppShellApplicationEnabled = (applicationFeatureFlags || [])?.find(flag => flag?.feature === "W_CDAS")?.enabled
  const isAppShellAbTestEnabled = (applicationFeatureFlags || [])?.find(flag => flag?.feature === "W_ASAB")?.enabled


  const isAppShellEnabled = (isAppShellUserEnabled || isAppShellApplicationEnabled)
  var storageItems = document.createElement("INPUT");
  var redirectUrl = document.createElement("INPUT");
  var localStorageInp = document.createElement("INPUT");

  var deviceParam = "ts:deviceId:" + window.userId;
  var sessionParam = "ts:sessionId:" + window.userId;
  const cdRedirectUrl = isAppShellEnabled ? window.routerappShellCDUrl : window.bauCustomerDashboardUrl;

  var storageItemsObj = {
      "AccessToken": Accesstoken,
      "pilotflags": PilotFlags,
      "isDIYPilot": isDIYPilot,
      "ts:usertkn": JwToken,
      "ts:userid": window.userId,
      "userId": window.userId,
      "currentSession": window.curSession,
      "ts:appid": window.appId,
      "InteractionID": interactionId,
      'applicationFeatureFlags': JSON.stringify(applicationFeatureFlags),
      'userFeatureFlags': JSON.stringify(userFeatureFlags),
      'UIEntryPointReturnURL': isAppShellEnabled ? window.routerappShellCDUrl : window.bauCustomerDashboardUrl,
      'AccountDashboardEntryPointURL': isAppShellEnabled ? window.routerappShellADUrl : window.bauAccountDashboardUrl,
      "Channel": decodedToken?.params?.channelId === 'web' ? 'OLB' : decodedToken?.params?.channelId,
      "appDTimers": JSON.stringify(window.appDTimers),
      "olbTimers": sessionStorage.getItem("olbTimers"),
      "loginWidget": sessionStorage.getItem("loginWidget"),
  };


  storageItemsObj[deviceParam] = window.devId;
  storageItemsObj[sessionParam] = window.sesId;

  storageItems.name = "storageItems";
  storageItems.value = JSON.stringify(storageItemsObj);
  storageItems.type = 'hidden';
  redirectUrl.name = "redirectUrl";
  redirectUrl.value = cdRedirectUrl;
  redirectUrl.type = 'hidden';

  localStorageInp.name = "localStorage";
  localStorageInp.value = localUserStorage;
  localStorageInp.type = 'hidden';

  laform.appendChild(storageItems);
  laform.appendChild(redirectUrl);
  laform.appendChild(localStorageInp);

  document.body.appendChild(laform);
  updateOlbTimers('DOTCOM', 'prepTimeBeforeFeatureRouter');
  laform.submit();
}

function readSessionStorageItemsForTransmit(jwtBodyJson, token) {
  var storage = window.sessionStorage;
  var length, params;
  if (!storage) return null;
  else {
      var userId = jwtBodyJson.sub.toLowerCase();
      storage.setItem('ts:deviceId:' + userId, jwtBodyJson.did);
      storage.setItem('ts:usertkn', token);
      storage.setItem('ts:appid', jwtBodyJson.aud);
      storage.setItem('ts:userid', userId);
      storage.setItem('ts:sessionId:' + userId, jwtBodyJson.sid);
      storage.setItem('InteractionID', jwtBodyJson.InteractionId);
  }
  
  length = storage.length;
  params = [];

  for (var index = 0; index < length; index++) {
      var key = storage.key(index);
      
      if (!key) {
          continue;
      }

      if (key.startsWith("ts:") || key.startsWith("currentSession")) {
          var value = storage.getItem(key);
          //replace <null> to null due to failure at server validation due to potential risk
          params.push({
              Key: key,
              Value: value.replace(/"/g, '\\"').replace(/<null>/g, 'null')
          });
      }
  }
  return params;
}

export const getManifestOrDeployedPath = () => {
    if(parseUrl && ( parseUrl.startsWith(Constants.IT_ENV) || parseUrl.startsWith(Constants.LOCALHOST_ENV))){
        return DotComConstants.IT_OLB_DOMAIN;
    } else if (parseUrl.startsWith(Constants.UAT_ENV) || parseUrl.startsWith(Constants.UAT_AAE_ENV)){
        return DotComConstants.UAT_OLB_DOMAIN;
    } else if (parseUrl.startsWith(Constants.EMP_ENV)){
        return DotComConstants.EMP_OLB_DOMAIN;
    } else{
        return DotComConstants.PROD_OLB_DOMAIN;
    }
}

export const getDotComAKValue = () => {
    const envType = getEnvType();
    if (parseUrl && envType === Constants.IT_ENV){
        return DotComConstants.DOTCOM_IT_AK_VALUE;
    } else if (envType === Constants.UAT_ENV || envType === Constants.UAT_AAE_ENV || envType === Constants.PERF_ENV || envType === Constants.PENTEST_ENV){
        return DotComConstants.DOTCOM_UAT_AK_VALUE;
    }
    return DotComConstants.DOTCOM_PROD_AK_VALUE;
}

export const getDotComAppDKey = () => {
    const env = getEnvType();
    return parseUrl && (env === Constants.IT_ENV || env === Constants.UAT_ENV || env === Constants.UAT_AAE_ENV || env === Constants.PERF_ENV || env === Constants.PENTEST_ENV) ? DotComConstants.DOTCOM_APPD_KEY_IT_UAT : DotComConstants.DOTCOM_APPD_KEY_PROD;
}

export const getEnvType = () => {

    if(parseUrl && ( parseUrl.startsWith(Constants.IT_ENV) || parseUrl.startsWith(Constants.LOCALHOST_ENV))){
        return Constants.IT_ENV;
    }else if (parseUrl.startsWith(Constants.UAT_ENV) || parseUrl.startsWith(Constants.UAT_AAE_ENV)){
        return Constants.UAT_ENV;
    }else if (parseUrl.startsWith(Constants.PERF_ENV)){
        return Constants.PERF_ENV;
    }else if (parseUrl.startsWith(Constants.PENTEST_ENV)){
        return Constants.PENTEST_ENV;
    }else{
        return Constants.PROD_ENV;
    }
}

export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = decodeURIComponent(atob(base64Url).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(base64);
};

export const updateOlbTimers = (appId,timerName) => {
    const olbTimersSession = sessionStorage.getItem('olbTimers');
    let parsedOlbTimersSession = olbTimersSession && JSON.parse(olbTimersSession);
    if(parsedOlbTimersSession) {
        if(!parsedOlbTimersSession[appId]) parsedOlbTimersSession[appId]={};
        parsedOlbTimersSession[appId][timerName] = new Date().getTime();
        sessionStorage.setItem('olbTimers',JSON.stringify(parsedOlbTimersSession));
    }
};

export const getDotComRoutingKey = () => {
    if(parseUrl && ( parseUrl.startsWith(Constants.IT_ENV) || parseUrl.startsWith(Constants.LOCALHOST_ENV))){
        return DotComConstants.DOTCOM_IT_ENV;
    } else if (parseUrl.startsWith(Constants.UAT_ENV) || parseUrl.startsWith(Constants.UAT_AAE_ENV)){
        return DotComConstants.DOTCOM_UAT_ENV;
    } else if (parseUrl.startsWith(Constants.EMP_ENV)){
        return DotComConstants.DOTCOM_EMP_ENV;
    } else{
        return DotComConstants.DOTCOM_PROD_ENV;
    }
}

export const logInfo = (comp,event,action='',msg='') => {
    const arr = [];
    Object.keys(jslogger).forEach(function(key) {
        arr.push(jslogger[key]);
    });
    const arrLoggerDetails = arr[0][comp][event];
    logger.info({
    // @ts-ignore
        eventSource: {
            businessCapability: arrLoggerDetails[Constants.LOGGER_BUSINESS_CAPABILITY],
            businessFunctionality: arrLoggerDetails[Constants.LOGGER_BUSINESS_FUNCTIONALITY],
            component: comp,
            stepName: arrLoggerDetails[Constants.LOGGER_STEPNAME],
        },
        eventStatus: arrLoggerDetails[Constants.LOGGER_EVENT_STATUS],
        eventAttributes: {
            action: action == '' ? arrLoggerDetails[Constants.LOGGER_ACTION] : action,
            message: msg,
            appId : 'DOTCOM',
            appVersion : '1.0',
            qmSession : window ? window.sessionStorage.getItem('qmSession') : ''
        },
    });
}
